import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/nowe-biuro-questy.jpg";

const Targiehandlu = ({ data }) => {
  return (
    <Artykul
      title="Witamy w nowym biurze!"
      keywords={["witamy w nowym biurze"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Witamy w nowym biurze"
      metaTitle="Witamy w nowym biurze!"
      metaDescription="Witamy w nowym biurze! ✅ Od 10 września 2018 r. działamy w Poznań Business Center • Questy - dostawcy oprogramowania dla biznesu"
    >
      <br />

      <p>
        Nasza firma stale się rozwija, mamy coraz więcej pracowników i pomysłów.
        Aby pomieścić całą załogę wraz z jej wszystkimi pomysłami zmieniliśmy
        lokalizację biura. Od 10 września 2018 roku działamy w Poznań Business
        Center przy ulicy Głogowskiej 31/33. Nowe biuro to kolejny rozdział w
        życiu firmy zupełnie nowe możliwości i perspektywy. Z okazji
        przeprowadzki przygotowaliśmy film, w którym prezentujemy nas samych i…
        nowe biuro! 🙂
      </p>
      <br />
      <br />
      <center>
        <iframe
          width="640"
          height="360"
          src="https://www.youtube.com/embed/Qa91CrMac_Y"
          frameborder="0"
          allow="accelerometer; autoplay; 
      encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </center>
    </Artykul>
  );
};

export default Targiehandlu;
